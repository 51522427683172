.separator {
  color: #000000;
  margin: 0 auto 20px;
  max-width: 240px;
  text-align: center;
  position: relative;
}
.separator:before, .separator:after {
  display: block;
  width: 40%;
  content: " ";
  margin-top: 10px;
  border: 1px solid #000000;
}
.separator:before {
  float: left;
}
.separator:after {
  float: right;
}

.separator-danger {
  color: #B603D1;
}
.separator-danger:before, .separator-danger:after {
  border-color: #B603D1;
}

.navbar .logo-container-auth {
  width: 195px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
  /*border-radius: 50%;*/
  border: 1px solid transparent;
}

.full-page .login-page, .full-page .register-page > .container {
    margin-top: 10vh; }

#tradesTable ReactTable .rt-th, .ReactTable .rt-td {
  white-space: normal;
}
#tradesTable ReactTable .rt-th, .ReactTable .rt-td:last-child {
  overflow: visible;
  width: 145px;
  min-width: 145px;
  max-width: 145px;
}


.collapsed {
  display: none;
}

.collapsed-group > div:not(.collapse-group-heading) {
  display: none;
}

.collapse-group-heading::after {
  flex-shrink: 0;
  width: 36px;
  height: 30px;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-19bqh2r'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-size: 1.25rem;
  background-position: center center;
  color: rgb(197, 197, 197);
  transform: rotate(180deg);
  background-color: #e9ecef;
}

.collapsed-group .collapse-group-heading::after {
  transform: rotate(0deg);
}

.collapse-group-heading {
  display: flex;
}
